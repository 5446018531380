import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
/* --- import Components ----*/
import Layout from 'src/components/Layout';
import { Section } from '../../components/Section';
import { RecruitersSection } from 'src/components/Partners';
import { HeroHomeWrapper } from 'src/components/Hero/elements';
import HeroText from 'src/components/LandingPage/HeroText';
import { MainVideo } from 'src/components/Projects';
import { CTASection } from 'src/components/CTA';
import ApplyFormLanding from 'src/components/ApplicationForm/landingPage/ApplyFormLanding';
import TestimonialsSectionLandingPage from 'src/components/LandingPage/TestimonialsSectionLandingPage';
import ProgramLandingPage from 'src/components/LandingPage/Program/ProgramLandingPage';
import QALandingPage from 'src/components/QuestionsAnswer/QALandingPage';
import ButtonScrollToForm from 'src/components/LandingPage/ButtonScrollToForm';
import FinancingLandingPage from 'src/components/LandingPage/FinancingLandingPage';
import FeaturesSection from 'src/components/Bootcamp/FeaturesSection';
/* --- import Utils ----*/
import { SessionDateDisplay, nextSessionsFilter } from 'src/utils/helpers';

const PartTimeLanding = ({ location, pageContext: { nextSessions } }) => {
  // image display
  const imageData = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "2023_students11.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      image2: file(relativePath: { eq: "2023_students4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      image3: file(relativePath: { eq: "2023_students5.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      video: file(relativePath: { eq: "2023_studentsAndTeacher3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // next session
  const nextPartTimeSession = nextSessionsFilter(
    nextSessions,
    'formation-developpeur-web-mobile-temps-partiel'
  );
  const date = SessionDateDisplay(nextPartTimeSession[0], 'Temps Partiel');

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C"
      pageName="Bootcamp full-time"
      pageType="landingPage"
      metaTitle="Le Reacteur | Apprendre à coder - Formation développeur Web et Mobile JavaScript"
      metaDescription="Apprenez à coder en rejoignant Le Reacteur, un coding bootcamp de 10 semaines, en présentiel (à Paris) ou à distance (en visio) et devenez Développeur Web et Mobile."
      css={{ paddingTop: 35 }}
    >
      {/* FIRST BANNER => form */}
      <Section theme="primary" hero landing>
        <HeroHomeWrapper landing>
          <HeroText fromPage="rncp6-partTime" />
          <ApplyFormLanding
            data={{
              bootcampType: 'Temps Partiel',
              certification: 'RNCP6',
              nextSessions: nextPartTimeSession,
            }}
          />
        </HeroHomeWrapper>
      </Section>
      {/* SECOND BANNER => video */}
      <Section landing>
        <MainVideo
          mainThumbnail={imageData.video.childImageSharp.fluid}
          link="https://www.youtube.com/embed/59XH_-eMQhE"
          loopRef="59XH_-eMQhE"
          noModal
        />
        <ButtonScrollToForm text={`Je postule\u00A0!`} />
      </Section>
      {/* THIRD BANNER => Testimonials */}
      <TestimonialsSectionLandingPage />
      {/* FOURTH BANNER => Objectives */}
      <ProgramLandingPage fromPage="rncp6-partTime" />
      {/* FIFTH BANNER => Picto */}
      <FeaturesSection
        fromPage="rncp6-partTime"
        landing
        images={[
          imageData.image1.childImageSharp.gatsbyImageData,
          imageData.image2.childImageSharp.gatsbyImageData,
          imageData.image3.childImageSharp.gatsbyImageData,
        ]}
      />
      {/* SIXTH BANNER => Financements */}
      <FinancingLandingPage />
      {/* SEVENTH BANNER => Companies */}
      <RecruitersSection theme="secondary" landing />
      {/* EIGTH BANNER => Ready to launch  */}
      <CTASection
        onBoardingPromise="24 semaines (6 mois) de code"
        course="Développeur Web et Mobile"
        nextSession={date}
        numberOfWeeks="24"
        format="à&nbsp;temps partiel"
        fromFormationsPageCertification="RNCP6"
        landing
      />
      {/* NINTH BANNER => FAQ  */}
      <QALandingPage fromPage="RNCP6-landing" />
    </Layout>
  );
};

export default PartTimeLanding;
